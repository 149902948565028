
import { defineComponent, reactive, ref, onMounted, UnwrapRef } from "vue";
import { notification } from "ant-design-vue";
import { ValidateErrorEntity } from "ant-design-vue/es/form/interface";
import validate from "../services/validator";
import superadminService from "../services/superadmin";
import {
} from "@ant-design/icons-vue";

interface Iorganisation {
  name: string;
  redirect: string;
}

export default defineComponent({
    emits: ["CloseOrganisationModal"],
    props: ['OrganisationID'],
    components: {
    },
  setup(props, { emit }) {
    const confirmLoading = ref<boolean>(false);
    const organisationFormRef = ref();
    const saveText = ref();
    const organisationRules = validate.organisationValidation;
    const organisationModal: UnwrapRef<Iorganisation> = reactive({
      name: '',
      redirect: '',
    });
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const sendNewOrganisation = async (value: any) => {
      confirmLoading.value = true;      
      try {
        let res, msg;
        if(props.OrganisationID) {
          res = await superadminService.editOrganisation(value, props.OrganisationID);
          if (res.data) {
            msg = 'Organisation updated succefully.';
          }
        } else {
          res = await superadminService.addOrganisation(value);
          if (res.data) {
            msg = 'Organisation created succefully.';
          }
        }
        await notify("Success", msg, "success");
        organisationFormRef.value.resetFields();
        emit("CloseOrganisationModal", 'SuccessModal');
        confirmLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log("Error", error.response);
          await notify("Error", error.response.data.message, "error");
        }
        confirmLoading.value = false;
      }
    };
    const sendRequest = async () => {
      organisationFormRef.value
        .validate()
        .then(() => {
          sendNewOrganisation(organisationModal);
        })
        .catch((error: ValidateErrorEntity<Iorganisation>) => {
          console.log("error", error);
        });
    };
    const getOrganisation = async (id: any) => {
      try {
        const res = await superadminService.getOrganisation(id);
        organisationModal.name = res.data.data.data.name;
        organisationModal.redirect = res.data.data.data.redirect;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response.data.message);
          await notify("Error", error.response.data.message, "error");
        }
      }
    };
    const cancelOrganisationrequest = async () => {
        emit("CloseOrganisationModal", 'CloseModal');
    }
    onMounted(async () => {
      if(props.OrganisationID) {
          saveText.value = "Update";
          getOrganisation(props.OrganisationID);
        } else {
          saveText.value = "Save";
          organisationModal.name = "";
          organisationModal.redirect = "";
        }
    });
    return {
      organisationFormRef,
      organisationModal,
      organisationRules,
      confirmLoading,
      sendRequest,
      notify,
      saveText,
      cancelOrganisationrequest,
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 16,
      },
    };
  },
});
