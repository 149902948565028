
import {
  defineComponent,
  ref,
  onMounted,
  createVNode,
} from "vue";
import { notification, Modal } from "ant-design-vue";
import superadminService from "../../services/superadmin";
import { EditOutlined, DeleteOutlined, CloseCircleOutlined } from "@ant-design/icons-vue";

import addNewOrganisationModal from "@/components/addNewOrganisation.vue";

export default defineComponent({
  components: {
    EditOutlined,
    DeleteOutlined,
    addNewOrganisationModal,
  },
  setup() {
    const organisationId = ref();
    const creatOrganisationVisible = ref<boolean>(false);
    const pageLoading = ref<boolean>(false);
    const organisationLists = ref([]);
    const organisationColumns = [
      {
        title: "Organisation ID",
        dataIndex: "id",
      },
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Key",
        dataIndex: "secret",
        class: "secret_key",
      },
      {
        title: "Redirect Url",
        dataIndex: "redirect",
        class: "redirect",
      },
      {
        title: "Action",
        align: "center",
        slots: { customRender: "action" },
      },
    ];
    const getAllOrganisation = async () => {
      pageLoading.value = true;
      try {
        const res = await superadminService.getAllOrganisations();
        organisationLists.value = res.data;
        pageLoading.value = false;
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
          pageLoading.value = false;
        }
      }
    };
    const showDeleteConfirm = async (organisationId: any) => {
      Modal.confirm({
        title: "Are you sure?",
        icon: createVNode(CloseCircleOutlined),
        content: createVNode(
          "p",
          {},
          "Do you really want to delete this organisation? This process cannot be undone."
        ),
        okText: "Delete",
        okType: "danger",
        cancelText: "Cancel",
        class: "delte-confirm-modal",
        async onOk() {
          await deleteOrganisation(organisationId);
        },
      });
    };
    const deleteOrganisation = async (organisationId: any) => {
      try {
        await superadminService.deleteOrganisation(organisationId);
        await notify("Success", "Organisation deleted successfully.", "success");
        getAllOrganisation();
      } catch (error: any) {
        if (error.response) {
          console.log(error.response);
        }
      }
    };
    const notify = async (
      message: string,
      description: string,
      type: string
    ) => {
      (notification as any)[type]({
        message,
        description,
      });
    };
    const addNewOrganisation = async () => {
      organisationId.value = undefined;
      addEditOrganisationTitle.value = "Add Organisation";
      creatOrganisationVisible.value = true;
    };
    const editOrganisation = async (data?: any) => {
      organisationId.value = data;
      addEditOrganisationTitle.value = "Edit Organisation";
      creatOrganisationVisible.value = true;
    };
    const closeOrganisationModal = async (data: any) => {
      if (data === "SuccessModal") {
        getAllOrganisation();
        creatOrganisationVisible.value = false;
      } else {
        creatOrganisationVisible.value = false;
      }
    };
    const addEditOrganisationTitle = ref();
    onMounted(async () => {
      getAllOrganisation();
    });
    return {
      addNewOrganisationModal,
      organisationColumns,
      organisationLists,
      showDeleteConfirm,
      creatOrganisationVisible,
      addNewOrganisation,
      closeOrganisationModal,
      pageLoading,
      editOrganisation,
      addEditOrganisationTitle,
      organisationId
    };
  },
});
